<template>
  <v-row>
    <v-col cols="12">
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th
                v-for="(header, i) in table.headers"
                :id="`header_${i}`"
                :key="`header_${i}`"
                :style="{ width: header.width, textAlign: header.textCenter ? 'center' : 'left' }"
                class="text-truncate"
              >
                {{ header.name }}
              </th>
            </tr>
          </thead>
          <tbody v-if="pagination && pagination.list.length > 0">
            <tr v-for="reg in pagination.list" :key="reg.idx">
              <td class="text-center text-truncate">
                {{ reg.product && reg.product.company ? reg.product.company.companyName : '-' }}
              </td>
              <td class="text-center text-truncate">
                {{ reg.alias ? reg.alias.username : reg.users.username }}
              </td>
              <td class="text-center text-truncate">
                <v-chip label :to="`/product/detail/${reg.product.idx}`" target="_blank">
                  {{ reg.product ? reg.product.month ? '[한달] ' + reg.product.productName : '' + reg.product.productName : '-' }}
                  <v-icon class="text-body-1 ml-2">
                    mdi-open-in-new
                  </v-icon>
                </v-chip>
              </td>
              <td class="text-center text-truncate">
                {{ reg.options ? reg.options.optionContent : '-' }}
              </td>
              <td class="text-center text-truncate">
                {{ reg.purchaseDate ? $parseDate(reg.purchaseDate) : '-' }}
              </td>
              <td class="text-center text-truncate">
                <v-btn
                  v-if="reg.article"
                  color="#555"
                  :small="$isMobile"
                  :to="`/article/${reg.article.idx}`"
                >
                  글 보기
                </v-btn>
                <v-dialog
                  v-else
                  v-model="reg.showRegister"
                  width="600"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      :small="$isMobile"
                      v-bind="attrs"
                      v-on="on"
                    >
                      글 작성
                    </v-btn>
                  </template>
                  <BoardWrite :companies="getCompanies(reg)" :product-registration="reg" @close-dialog="close(reg)" />
                </v-dialog>
                <v-btn
                  class="ml-2"
                  :small="$isMobile"
                  @click="deleteProductRegistration(reg)"
                >
                  신청취소
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
  import BoardWrite from '@/views/board/Register'
  export default {
    name: 'ProductRegistrationContent',
    components: { BoardWrite },
    props: {
      pagination: {},
    },
    data () {
      return {
        table: {
          headers: [
            {
              name: '업체',
              width: 'auto',
              textCenter: true,
            },
            {
              name: '구매자',
              width: 'auto',
              textCenter: true,
            },
            {
              name: '키워드',
              width: 'auto',
              textCenter: true,
            },
            {
              name: '옵션명',
              width: 'auto',
              textCenter: true,
            },
            {
              name: '구매예정',
              width: 'auto',
              textCenter: true,
            },
            {
              name: '상태',
              width: 'auto',
              textCenter: true,
            },
          ],
        },
      }
    },
    methods: {
      getCompanies (reg) {
        const companies = []
        companies.push(reg.product.company)
        return companies
      },
      async deleteProductRegistration (registration) {
        if (confirm('상품에 대한 신청을 취소하시겠습니까?')) {
          const url = `/product/registration/${registration.idx}`
          await this.$axios.delete(url).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              this.$emit('refresh')
            } else {
              alert(res.data.message || '오류가 발생하였습니다.')
            }
          }).catch(() => {
            alert('오류가 발생하였습니다.')
          })
        }
      },
      close (reg) {
        reg.showRegister = false
        this.$emit('refresh')
      },
    },
  }
</script>

<style scoped>

</style>
