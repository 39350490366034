<template>
  <v-row>
    <v-col cols="12">
      <v-subheader class="px-0">
        Products / New Products / Product Registration ({{ pagination ? pagination.totalDataSize : 0 }})
      </v-subheader>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'ProductRegistrationHeader',
    props: {
      pagination: {},
    },
  }
</script>

<style scoped>

</style>
